/**
 * Referance 
 * https://medium.com/@DenysVuika/simple-i18n-support-for-your-angular-apps-6138a47eb2a9
 * 
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  data: any = {};
  lang: string;
  constructor(private http: HttpClient) { }
  use(lang: string): Promise<{}> {
    if (lang !== 'en' && lang !== 'ar') {
      lang = 'en';
    }
    window.localStorage.language = lang;
    this.lang = lang;

    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang || 'en'}.json`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error => {
          this.data = {};
          reject(error);
        }
      );
    });
  }
}
