import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetInTouchService {




  constructor(private http: HttpClient) { }

  public handleError(error: HttpErrorResponse | any) {

    // In a real-world app, we might use a remote logging infrastructure

    //let errMsg: string;
    let errMsg: any;

    if (error instanceof HttpErrorResponse) {

      const body = error.error || '';

      const err = body || JSON.stringify(body);

      errMsg = err.text;
      //errMsg = error;

    } else {

      errMsg = error.message ? error.message : error.toString();

    }

    return throwError(errMsg);



  }


  public header() {

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('apiKey', environment.apiKey);
    header = header.append('apiPassword', environment.apiPassword);

    return { headers: header};
  }


  getInTouch(data: any): any {
    return this.http.post(environment.apiUrl + '/api/Account/GetInTouch', data, this.header()).pipe(catchError(this.handleError));
  }
}
