import { Component, OnInit } from '@angular/core';
import { GetInTouchService } from '../get-in-touch.service';

@Component({
  selector: 'app-business-platform',
  templateUrl: './business-platform.component.html',
  styleUrls: ['./business-platform.component.css']
})
export class BusinessPlatformComponent implements OnInit {

  contactSubmitted = false;
  fName: string;
  lName: string;
  email: string;
  name: string;
  fleetCountText: string;
  phoneNumber: string;
  companyName: string;

  constructor( private getInTouchService: GetInTouchService) { }

  ngOnInit() {
  }

  submitContact(form) {
    const data = {
      phoneNumber: '+965 ' + form.value.phoneNumber,
      name: form.value.fName + ' ' + form.value.lName,
      message: 'Fleet Count: ' + form.value.fleetCountText + ' CompanyName:' + form.value.companyName,
      email: form.value.email,
      source: 'Website',
      type: 'Header'
    };

    // Regardless of success or error reset the form and show success
    this.contactSubmitted = true;
    form.reset();
    setTimeout(() => {
      this.contactSubmitted = false;
    }, 3000);

    this.getInTouchService.getInTouch(data).subscribe(res => { }, err => { });
  }

}
