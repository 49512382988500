import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlCarousel } from 'ngx-owl-carousel';
import { Asma } from '../../model/Asma';
import { Testimonial } from '../../model/testimonial';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.css']
})
export class AboutUsPageComponent implements OnInit {
  name: string;
  phoneNumber: string;

  constructor(private titleService: Title) {
    this.titleService.setTitle('Saiyarti-About Us');
  }

  
  index = 0;
  testimonials: Testimonial[] = [];
  userReviews: Testimonial[] = [];
  info = [];
  Asmas: Asma[] = [];

    carouselOptions = {
      nav: false,
      dots: false,
      loop: true,
      pagination: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1.2,
          autoplay: true,
          autoplaySpeed: 5000,
          loop: true,
          slideTransition: 'linear',
          nav: false
        },
        600: {
          items: 3,
        },
        1000: {
          items: 4,
        },
        1500: {
          items: 3,
        }
      }
    };

    ExeOptions = {
      nav: false,
      dots: false,
      loop: true,
      pagination: true,
      responsiveClass: true,
      responsive: {
        0: {
          autoplay: true,
          autoplaySpeed: 1000,
          items: 1,
          dots: false,
          loop: true
        },
        600: {
          items: 2,
        },
        1000: {
          items: 4,
          loop: false
        },
        1500: {
          items: 3,
          loop: false
        }
      }
    };

  InsuOptions = {
    nav: false,
    dots: false,
    loop: true,
    responsiveClass: true,
    autoplay: true,
    slideTransition: 'linear',
    autoplaySpeed: 5000,
    autoWidth: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
      1500: {
        items: 5,
      }
    }
  };

  Cimages = [
    {
      src: 'assets/image/partner_logo_01_gig.png',
      width: '78px',
    },
    {
      src: 'assets/image/partner_logo_02_boubyan.png',
      width: '141px',
    },
    {
      src: 'assets/image/partner_logo_03_ain.png',
      width: '180px',
    },
    {
      src: 'assets/image/partner_logo_04_aman.png',
      width: '87px',
    },
    {
      src: 'assets/image/partner_logo_05_firsttakaful.png',
      width: '180px',
    },
    {
      src: 'assets/image/partner_logo_06_ghazal.png',
      width: '77px',
    },
    {
      src: 'assets/image/partner_logo_07_oriental.png',
      width: '84px',
    }
  ];

  images = [
    {
      Fimage: 'assets/image/logo_aljarida.png',
      url: 'https://www.aljarida.com/articles/1584803868308889000/'
    },
    {
      Fimage: 'assets/image/logo_bazaartown.png',
      url: 'https://bazaar.town/saiyarti/'
    },
    {
      Fimage: 'assets/image/logo_association_timeskuwait.png',
      url: 'https://www.timeskuwait.com/news/new-app-for-motor-insurance-launched/'
    },
    {
      Fimage: 'assets/image/logo_q8india.png',
      url: 'https://www.q8india.com/blog/2020/04/06/saiyarti-buying-renewing-motor-third-party-liability-insurance-made-easy/'
    }
  ];

  testimonialOptions = {
    loop: true,
    nav: false,
    items: 1,
    dots: false,
    responsive: {
      0: {
        items: 1,
        dots: true
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
        dots: false
      },
      1500: {
        items: 1,
      }
    }
  };

  ngOnInit() {

    let asma = new Asma();
    asma.name = 'Authorized Signatory';
    asma.nameAr = 'المفوض بالتوقيع ';
    asma.LastName = 'AL ZAHEM & MALHOTRA GENERAL TRADING CO. W.L.L';
    asma.LastNameAr = '.شركة الزاحم و ملهوترا للتجارة العامة ذ.م.م ';
    asma.detail = 'Our company has been associated with Insure and Secure since its inception in 2010. Availing the services of Insure & Secure has been a source of true pleasure for us. Not only we receive various insurace coverages & associated services from them, we also consistently receive invaluable advice about how best our interests can be served and different aspects of risk to our business can be protected.';
    asma.detailAr = 'ارتبطت شركتنا بـشركة إنشور آند سيكيور منذ إنشائها في عام 2010. وكان الاستفادة من خدمات التأمين مصدرًا للسعادة الحقيقية بالنسبة لنا. لا نتلقى فقط العديد من التغطيات التأمينية والخدمات المرتبطة بها ، بل نتلقى أيضًا نصائح لا تقدر بثمن حول أفضل السبل التي يمكن بها خدمة مصالحنا وحماية الجوانب المختلفة للمخاطر على أعمالنا. وجدنا منهم تنفيذ العمل سريعًا و مجتهدين ومهتمين بمتطلباتنا. يسعدنا أن نوصي أي شخص جاد بشأن تأمين مصالحهم التجارية للعمل مع إنشور آند سيكيور لوساطة التأمين. ';
    asma.detail2 = 'We found them prompt, diligent and attentive to our requirements. We would happily recommend anyone serious about securing their business interests to work with Insure & secure.'

    this.Asmas.push(asma);

    asma = new Asma();
    asma.name = 'Gurvinder Singh Lamba';
    asma.nameAr = 'جورفيندر سينغ لامبا ';
    asma.LastName = 'Ahmed Yousif Abdulhadi Almailem & Bros Co. W.L.L.';
    asma.LastNameAr = 'شركة أحمد يوسف الميلم و اخوانه ذ.م.م. ';
    asma.detail = 'We have been associated with Insure & Secure Insurance Brokerage Company WLL since 2013. They have been providing us with guidance and recommendations of our insurance needs from time to time, which added value to our business. They are always available to us on our doorstep to resolve any difficulties, which is a very crucial feature in our long association.';
    asma.detailAr = 'لقد ارتبطنا بشركة إنشور آند سيكيور لوساطة التأمين منذ عام 2013. لقد قاموا بتزويدنا بالإرشادات والتوصيات حول احتياجات التأمين لدينا من وقت لآخر ، مما أضاف قيمة إلى أعمالنا. هم دائمًا قريبون لنا و على عتبة بابنا لحل أي صعوبات ، وهي ميزة بالغة الأهمية في علاقتنا الطويلة. لقد استفادنا من نهجهم المهني في تقديم التوجيه بصفتهم وسيط تأمين في هذه العلاقة طويلة الأمد ويمكننا أن نوصي دون تردد شركة إنشور آند سيكيور لوساطة لأي شركة ترغب في العمل معهم. ';
    asma.detail2 = 'Their professional approach in providing guidance as an insurance broker benefited us in this long-term relationship and we could recommend without hesitation Insure and secure Brokerage Company for any company who is willing to work with them.'

    this.Asmas.push(asma);

    asma = new Asma();
    asma.name = 'Rishabh Saraogi';
    asma.nameAr = 'ريشابه ساروجي ';
    asma.LastName = 'Asmacs General Trading Company W.L.L';
    asma.LastNameAr = 'شركة اسماكس للتجارة العامة ذ.م.م. ';
    asma.detail = 'We have been dealing with Insure and Secure for last 8-9 years and have found their services to be very professional and reliable. They have a very customer centric and proactive approach, they always provide us options that fit to our specific needs. We highly recommend their services as an insurance intermediary.';
    asma.detailAr = 'لقد تعاملنا مع إنشور آند سيكيور منذ 8 إلى 9 سنوات ووجدنا أن خدماتهم محترفة وموثوقة دائماً. لديهم نهج استباقي يركز على العملاء بشدة ، ويقدمون لنا دائمًا خيارات تناسب احتياجاتنا الخاصة. نوصي بشدة بخدماتهم كوسيط تأمين ';

    this.Asmas.push(asma);

    asma = new Asma();
    asma.name = 'Gwand-Seok';
    asma.nameAr = 'غواند سوك ';
    asma.LastName = 'SBC General Trading & Contracting Company W.L.L';
    asma.LastNameAr = 'شركة اس بي سي للتجارة العامة والمقاولات ذ.م.م.' ;
    asma.detail = 'On behalf of SBC General Trading and Contracting Co WLL, We would like to appreciate Insure and Secure Insurance Brokerage Company WLL for the quality and Timeliness Insurance services(Group life & Personal Accident, Workmen compensation, Employer’s Liability, Contractor Plant & Machinery, All Risk Policies etc.) provided to our company since 2016 for various KNPC/ KOC Construction Projects in Kuwait. Any inquiries we had with your company have been always handed in a professional manner and we are very pleased with your services.';
    asma.detailAr = 'بالنيابة عن شركة اس بي سي للتجارة العامة والمقاولات ذ.م.م. ، نود أن نقدر شركة إنشور آند سيكيور لوساطة التأمين على خدمات التأمين ذات الجودة والسرعة في الأداء (المجموعة على الحياة والحوادث الشخصية ، وتعويض العمال ، ومسؤولية صاحب العمل ، ومصنع وآلات المقاول ، وجميع مخاطر الوثائق وما إلى ذلك) المقدمة لشركتنا منذ عام 2016 لمختلف مشاريع البناء التابعة لشركة البترول الوطنية الكويتية / شركة نفط الكويت. أي استفسارات لدينا مع شركتك تم تسليمها دائمًا بطريقة احترافية ونحن سعداء جدًا بالخدمات التي تقدمها لنا. لقد كان من دواعي سروري القيام بأعمال تجارية مع شركة إنشور آند سيكيور لوساطة التأمين ذ.م.م. ، تغتنم إدارة  شركة اس بي سي هذه الفرصة لتوجيه الشكر لك وتهنئة موظفيك على هذا الأداء المتميز. ';
    asma.detail2 = 'It has been a pleasure to do business with Insure and Secure Insurance Brokerage Company WLL SBC management takes this opportunity to thank and congratulate you and your staff in this outstanding performance.'

    this.Asmas.push(asma);

    asma = new Asma();
    asma.name = 'Vinukumar Nair';
    asma.nameAr = 'فينوكومار ناير ';
    asma.detail = 'Congratulations team Saiyarti for your creativity and initiative to launch the first mobile App for insurance in Kuwait.';
    asma.detailAr = 'تهانينا لفريق سيارتي على إبداعكم ومبادرتكم بإطلاق أول تطبيق على الموبايل للتأمين في الكويت. نريدك أن تعلم أنني مسرور جدًا بجودة الخدمة التي تقدمها شركتك. أقدر بصدق استجابتك والطريقة التي تدير بها عملك. لقد أوصيت بخدمتك للآخرين بسبب رضانا عن خدمتك وهي قيمة تستحق مقابل المال. إنني أتطلع إلى التعامل معك لسنوات قادمة. ';
    asma.detail2 = 'We want you to know that I am very pleased with the quality of service your company provides. I sincerely appreciate your responsiveness and the way you conduct business. I have recommended your service to others because of our satisfaction with your service and it’s value for money . I look forward to doing business with you for years to come.'

    this.Asmas.push(asma);

    asma = new Asma();
    asma.name = 'Sunny Bhatia';
    asma.nameAr = 'صني بهاتيا ';
    asma.detail = 'Extremely relevant in the times of social distancing! In these times I was able to renew my car insurance with a few clicks and a couple of documents upload and got the policy delivered to home! Back office staff was helpful and reliable. Efficient, reliable and convenient! Congratulations for bringing car insurance on e-commerce platform and look forward for your success in offering other associated services';
    asma.detailAr = 'مهم للغاية في أوقات التباعد الاجتماعي! في هذه الأوقات ، تمكنت من تجديد التأمين على سيارتي ببضع نقرات وتحميل بعض المستندات وتم تسليم الوثيقة إلى المنزل! كان موظفو المكتب الخلفي متعاونين وموثوقين.وكانت عملية فعالة وموثوقة ومريحة! تهانينا على توفير التأمين على السيارات على منصة التجارة الإلكترونية ونتطلع إلى نجاحك في تقديم خدمات أخرى. ';

    this.Asmas.push(asma);

    asma = new Asma();
    asma.name = 'Muthumanickam';
    asma.nameAr = 'موثومانيكام';
    asma.detail = 'It was so easy that Even person\'s who don\'t have much knowledge about online purchase can buy it by simply uploading the required documents. It took less than 5 minutes for me to do.I utiliséd my travel time to the insurance office in meeting my friend and it is cheaper than most of the policies offered in Kuwait.';
    asma.detailAr = 'كان الأمر سهلاً لدرجة أنه حتى الأشخاص الذين ليس لديهم الكثير من المعرفة حول الشراء عبر الإنترنت يمكنهم شرائه بمجرد تحميل المستندات المطلوبة. استغرق الأمر أقل من 5 دقائق بالنسبة لي.';
    asma.detail2 = 'I have already started sharing about Saiyarti and its hassle free benefits to my friends here in Kuwait.I thank Saiyarti for their truely professional customer service.';
    asma.detail2Ar = 'استفدت من وقت سفري إلى مكتب التأمين في مقابلة صديقي وهو أرخص من معظم الوثائق المعروضة في الكويت. لقد بدأت بالفعل في مشاركة معلومات عن سيارتى ومزاياها الخالية من المتاعب لأصدقائي هنا في الكويت. أشكر سيارتى على خدمة العملاء المحترفة حقًا.'

    this.Asmas.push(asma);

    asma = new Asma();
    asma.name = 'Royden Fernandes';
    asma.nameAr = 'رويدين فرانسيس فرنانديز';
    asma.detail = 'Smooth and hassle free process for profile registration and insurance policy purchase or renewal. Saves time and also gets the papers delivered to your doorstep. Would recommend the app to other users for sure. However, team needs to develop a proof of purchase or payment receipt email notification for customers to receive the payment confirmation as a soft copy for further reference until the original papers get delivered.';
    asma.detailAr = 'عملية سلسة وخالية من المتاعب لتسجيل وشراء أو تجديد وثيقة التأمين.  يوفر الوقت وأيضًا توصيل الأوراق إلى عتبة داركم.  أوصي بالتطبيق للمستخدمين الآخرين بالتأكيد.  ومع ذلك ، يحتاج الفريق إلى تطوير دليل على الشراء أو إشعار إيصال الدفع عبر البريد الإلكتروني للعملاء لتلقي تأكيد الدفع كنسخة إلكترونية للرجوع إليها بشكل إضافي حتى يتم تسليم الأوراق الأصلية.';

    this.Asmas.push(asma);


    let testimonial = new Testimonial();
    testimonial.exeImg = '/assets/image/ic_partners.png';
    testimonial.exeNumber = '14+';
    testimonial.exeNumberAr = '14+';
    testimonial.exePosition = 'Partner Companies';
    testimonial.exePositionAr = 'شركة شريك';
  
    this.testimonials.push(testimonial);

    testimonial = new Testimonial();
    testimonial.exeImg = '/assets/image/ic_policies.png';
    testimonial.exeNumber = '3k+';
    testimonial.exeNumberAr = '3k+';
    testimonial.exePosition = 'Insurance Delivered';
    testimonial.exePositionAr = 'تم تسليم وثيقة ';

    this.testimonials.push(testimonial);

    testimonial = new Testimonial();
    testimonial.exeImg = '/assets/image/ic_customers.png';
    testimonial.exeNumber = '4200+';
    testimonial.exeNumberAr = '4200+';
    testimonial.exePosition = 'customers';
    testimonial.exePositionAr = 'عميل ';

    this.testimonials.push(testimonial);
  }
}
