import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetInTouchService } from '../get-in-touch.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DialogServiceService } from '../dialog-service.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private getInTouchService: GetInTouchService, private snackBar: MatSnackBar, private dialogService: DialogServiceService) { }

  ngOnInit() {
    this.whyChooseSlider();
    this.partnerSlider();
  }

  playVideo() {
    if (document.body.className.split(' ').indexOf('en') > -1) {
      this.dialogService.videoPlayDialog('https://saiyarti-prod-cdn.azureedge.net/assets/saiyarti_introduction.mp4');
    } else {
      this.dialogService.videoPlayDialog('https://saiyarti-prod-cdn.azureedge.net/assets/saiyarti_introduction_arabic.mp4');
    }
  }

  submitEmai(form) {
    console.log(form.value.getEmail);
    const data = {
      email: form.value.getEmail,
      source: 'Website',
      type: 'Query'
    };

    this.getInTouchService.getInTouch(data).subscribe(res => {
      console.log(res);
      form.value.getEmail =
        this.snackBar.open('Success.', 'CANCEL', {
          horizontalPosition: 'right'
        });
      form.reset();
    }, err => {
      console.log(err);
      // this.error = err;
      // this.diloag.open(AlertDialogComponent, { data: { title: 'Error', message: 'Error to create company.' } });
      this.snackBar.open(err, 'CANCEL', {
        horizontalPosition: 'right'
      });
    });

  }

  whyChooseSlider() {
    $(document).ready(() => {
      const $viewBox = $('.why-choose-slider');
      const $boxContainer = $('.why-choose-slider > div');
      const $box = $('.why-choose-slider .inlinFlex');
      const boxwidth = $box.outerWidth();
      const boxCount = $box.length;
      const viewBoxWidth = $viewBox.width();
      const viewableBoxCount = parseInt((viewBoxWidth / boxwidth) + '', 10);


      $('#slideLeft').on('click', () => {
        if ($(':animated').length) {
          return false;
        }

        const leftPoint = parseInt($boxContainer.css('left'), 10);
        console.log(leftPoint + ' < ' + ((boxCount - viewableBoxCount) * boxwidth));
        console.log(leftPoint);
        if (document.body.className.split(' ').indexOf('en') > -1) {
          if (leftPoint >= -600) {
            $boxContainer.animate({ left: '-=' + boxwidth + 'px' });
            // $("#slideLeft").show();
          } else {
            // $("#slideRight").hide();
          }
        } else {
          if (leftPoint > 0) {
            $boxContainer.animate({ left: '-=' + boxwidth + 'px' });
            // $("#slideLeft").show();
          } else {
            // $("#slideRight").hide();
          }
        }
      });

      $('#slideRight').on('click', () => {
        if ($(':animated').length) {
          return false;
        }
        const leftPoint = parseInt($boxContainer.css('left'), 10);
        console.log(leftPoint);
        if (document.body.className.split(' ').indexOf('en') > -1) {
          if ((boxwidth + leftPoint) <= 0) {
            $boxContainer.animate({ left: '+=' + boxwidth + 'px' });
            // $("#slideLeft").show();
          } else {
            // $("#slideRight").hide();
          }
        } else {
          if ((boxwidth + leftPoint) <= 700) {
            $boxContainer.animate({ left: '+=' + boxwidth + 'px' });
            // $("#slideLeft").show();
          } else {
            // $("#slideRight").hide();
          }
        }
      });

    });
  }


  partnerSlider() {
    $(document).ready(() => {
      const $viewBox = $('.partners-slider');
      const $boxContainer = $('.partners-slider > div');
      const $box = $('.partners-slider .inlinFlex');
      const boxwidth = $box.outerWidth();
      const boxCount = $box.length;
      const viewBoxWidth = $viewBox.width();
      const viewableBoxCount = parseInt((viewBoxWidth / boxwidth) + '', 10);

      console.log('Box Count: ' + boxCount);
      console.log('Viewable Box Count: ' + viewableBoxCount);
      console.log('Box Width: ' + boxwidth);
      console.log('ViewBox Width: ' + viewBoxWidth);

      $('#partnerSlideLeft').on('click', () => {
        if ($(':animated').length) {
          return false;
        }

        const leftPoint = parseInt($boxContainer.css('left'), 10);
        console.log('Check Point: ' + Math.abs(leftPoint) + ' < ' + ((boxCount - viewableBoxCount) * boxwidth));
        console.log('Left Point' + leftPoint);


        if (document.body.className.split(' ').indexOf('en') > -1) {
          if (Math.abs(leftPoint) < ((boxCount - viewableBoxCount) * boxwidth)) {
            $boxContainer.animate({ left: '-=' + boxwidth + 'px' });
            // $("#slideLeft").show();
          } else {
            // $("#slideRight").hide();
          }
        } else {
          if (leftPoint > 0) {
            $boxContainer.animate({ left: '-=' + boxwidth + 'px' });
            // $("#slideLeft").show();
          } else {
            // $("#slideRight").hide();
          }
        }
      });

      $('#partnerSlideRight').on('click', () => {
        if ($(':animated').length) {
          return false;
        }
        const leftPoint = parseInt($boxContainer.css('left'), 10);
        console.log('Left Point' + leftPoint);
        if (document.body.className.split(' ').indexOf('en') > -1) {
          if ((boxwidth + leftPoint) <= 0) {
            $boxContainer.animate({ left: '+=' + boxwidth + 'px' });
            // $("#slideLeft").show();
          } else {
            // $("#slideRight").hide();
          }
        } else {
          console.log('Check Point: ' + Math.abs(leftPoint) + ' > ' + ((boxCount - viewableBoxCount) * boxwidth));
          if (Math.abs(leftPoint) < ((boxCount - viewableBoxCount) * boxwidth)) {
            $boxContainer.animate({ left: '+=' + boxwidth + 'px' });
            // $("#slideLeft").show();
          } else {
            // $("#slideRight").hide();
          }
        }
      });

    });
  }



}
