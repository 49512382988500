import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from 'src/app/translate.service';
declare var $: any;

@Component({
  selector: 'app-verified-email',
  templateUrl: './verified-email.component.html',
  styleUrls: ['./verified-email.component.css']
})
export class VerifiedEmailComponent implements OnInit {
  name = '';
  lang = 'en';
  constructor(private route: ActivatedRoute, private translate: TranslateService) { }

  ngOnInit() {
    this.name = this.route.snapshot.queryParamMap.get('name');
    this.lang = this.route.snapshot.queryParamMap.get('lang');
    if (this.lang === 'ar') {
      this.setLang('ar');
    }
  }

  setLang(lang: string) {
    this.translate.use(lang);
    $(document).ready(() => {
      $('body').removeClass('en');
      $('body').removeClass('ar');
      $('body').addClass(lang);
    });
  }

}
