import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit, OnDestroy {

  partnerImage = '';
  partnerAppStore = '';
  partnerPlayStore = '';
  partnerQR = '';
  constructor(private activatedRoute: ActivatedRoute, private titleService: Title,
    @Inject(DOCUMENT) document) {

    this.activatedRoute.params.subscribe(event => {
      if (event.partner === 'timeskuwait') {
        this.partnerImage = 'assets/image/logo_timeskuwait.png';
        this.partnerPlayStore = 'https://bit.ly/2yAFYad';
        this.partnerAppStore = 'https://bit.ly/2XhriYa';
        this.partnerQR = 'assets/image/qrcode_timeskuwait.jpg';
        this.titleService.setTitle('Saiyarti-Times Kuwait');
      } else if (event.partner === 'tristar') {
        this.partnerImage = 'assets/image/logo_association_tristar.jpg';
        this.partnerPlayStore = 'https://bit.ly/2V2unK0';
        this.partnerAppStore = 'https://bit.ly/2RzCRpH';
        this.partnerQR = 'assets/image/qrcode_tristar.jpg';
        this.titleService.setTitle('Saiyarti-Tristar');
      } else if (event.partner === 'q8india') {
        this.partnerImage = 'assets/image/logo_association_q8india.png';
        this.partnerPlayStore = 'https://bit.ly/39ZFeIQ';
        this.partnerAppStore = 'https://bit.ly/3ceJUMt';
        this.partnerQR = 'assets/image/qrcode_q8india.jpg';
        this.titleService.setTitle('Saiyarti-Q8 India');
      }
    });
  }
  ngOnDestroy(): void {
    this.titleService.setTitle('Saiyarti');
    // document.getElementById('change-language').style.display = 'block';
  }

  ngOnInit() {
  }

}
