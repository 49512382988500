import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.css']
})
export class SolutionComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle('Saiyarti-Solution');
  }

  name: string;
  phoneNumber: string;
  carouselOptions = {
    margin: 25,
    nav: false,
    navText: ['<div class=\'nav-btn prev-slide\'></div>', '<div class=\'nav-btn next-slide\'></div>'],
    responsiveClass: true,
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 2,
        nav: false
      },
      1000: {
        items: 4,
        loop: false
      },
      1500: {
        items: 3,
        loop: false
      }
    }
  };


  images = [
    {
      image: 'assets/image/logo_bazaartown.png'
    },
    {
      image: 'assets/image/logo_bazaartown.png'
    },
    {
      image: 'assets/image/logo_bazaartown.png'
    },
    {
      image: 'assets/image/logo_bazaartown.png'
    },
    {
      image: 'assets/image/logo_bazaartown.png'
    },
    {
      image: 'assets/image/logo_bazaartown.png'
    }
  ];

  ngOnInit() {
  }
}
