import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar } from '@angular/material/legacy-snack-bar';
import { buffer, take, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OgCustomer } from 'src/model/customer';
import { Fee } from 'src/model/fee';
import { PurposeOfLicense } from 'src/model/purposeOfLicense';
import { purposeOfLicenseAr } from 'src/model/purposeOfLicenseAr';
import { TPLService } from '../tpl.service';
import { TranslateService } from '../translate.service';
import { CatalogService } from '../services/catalog.service';
import { PhoneNumberVerificationComponent } from '../dialog/phone-number-verification/phone-number-verification.component';
import { AccountService } from '../account.service';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { LoaderDialogComponent } from '../dialog/loader-dialog/loader-dialog.component';

@Component({
  selector: 'app-policy-detail-og',
  templateUrl: './policy-detail-og.component.html',
  styleUrls: ['./policy-detail-og.component.css']
})
export class PolicyDetailOgComponent implements OnInit, OnDestroy {

  customer: OgCustomer;
  fees: [Fee];
  selectedFee: Fee;
  fee: Fee;
  selectedLicense: PurposeOfLicense;
  userId: string;

  // Car Make
  get CarMake() {
    return this.customer.carMake;
  }
  set CarMake(value) {
    this.customer.carMake = value;
    this.getCarModels();
    this.customer.carMakeAr = '';
    this.customer.carModel = '';
    this.customer.trim = '';
  }

  get CarMakeAr() {
    return this.customer.carMakeAr;
  }
  set CarMakeAr(value) {
    this.customer.carMakeAr = value;
    this.getCarModels();
    this.customer.carMake = '';
    this.customer.carModelAr = '';
    this.customer.trimAr = '';
  }

  // Car Model
  get CarModel() {
    return this.customer.carModel;
  }
  set CarModel(value) {
    this.customer.carModel = value;
    this.getCarTrim();
    this.customer.carModelAr = '';
    this.customer.trim = '';
  }

  get CarModelAr() {
    return this.customer.carModelAr;
  }
  set CarModelAr(value) {
    this.customer.carModelAr = value;
    this.getCarTrim();
    this.customer.carModel = '';
    this.customer.trimAr = '';
  }

  // Model Year
  get ModelYear() {
    return this.customer.modelYear;
  }
  set ModelYear( value ) {
    this.customer.modelYear = value;
    this.getQuote();
  }

  // Car Trim
  get CarTrim() {
    return this.customer.trim;
  }
  set CarTrim(value) {
    this.customer.trim = value;
    this.getPassengerCount();
    this.customer.trimAr = '';
  }

  get CarTrimAr() {
    return this.customer.trimAr;
  }
  set CarTrimAr(value) {
    this.customer.trimAr = value;
    this.getPassengerCount();
    this.customer.trim = '';
  }

  get SelectedLicense() {
    return this.selectedLicense;
  }
  set SelectedLicense( value ) {
    this.selectedLicense = value;
    if ( value ) {
      this.getQuote();
    }
  }

  selectedLicenseAr: purposeOfLicenseAr;
  get SelectedLicenseAr() {
    return this.selectedLicenseAr;
  }
  set SelectedLicenseAr( value ) {
    this.selectedLicenseAr = value;
    if ( value ) {
      this.getQuote();
    }
  }

  carMakeList: any;
  carModelList: any;
  carTrimList: any;

  carMakeListAr: any;
  carModelListAr: any;
  carTrimListAr: any;

  passengers;

  load = 0;
  hasQuote = false;
  token: string;
  getQuoteRef: MatSnackBarRef<SimpleSnackBar>;
  purposeOfLicenses =
    [
      new PurposeOfLicense(0, 'Private'),
      new PurposeOfLicense(1, 'Taxi'),
      new PurposeOfLicense(2, 'Japanese Pick Up'),
      new PurposeOfLicense(3, 'American Pick Up'),
      new PurposeOfLicense(4, 'Water Tank'),
      new PurposeOfLicense(5, 'Ambulance'),
      new PurposeOfLicense(6, 'Motorcycle'),
      new PurposeOfLicense(7, 'Crane'),
      new PurposeOfLicense(8, 'Bus'),
      new PurposeOfLicense(12, 'Mixer'),
      new PurposeOfLicense(13, 'Fork Cliff'),
      new PurposeOfLicense(15, 'Tipper'),
      new PurposeOfLicense(18, 'Half Lorry'),
    ];

  purposeOfLicensesAr =
    [
      new purposeOfLicenseAr(0, 'خاصة'),
      new purposeOfLicenseAr(0, 'تاكسي'),
      new purposeOfLicenseAr(0, 'ونيت ياباني'),
      new purposeOfLicenseAr(0, 'ونيت أمريكي'),
      new purposeOfLicenseAr(0, 'خزان مياه'),
      new purposeOfLicenseAr(0, 'إسعاف'),
      new purposeOfLicenseAr(0, ' دراجة بخارية'),
      new purposeOfLicenseAr(0, 'رافعة (كرين)'),
      new purposeOfLicenseAr(0, 'باص'),
    ];

  /** control for the selected carMake */
  public carMakeCtrl: UntypedFormControl = new UntypedFormControl();
  public carMakeCtrlAr: UntypedFormControl = new UntypedFormControl();
  /** control for the MatSelect filter keyword */
  public carMakeFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public carMakeFilterCtrlAr: UntypedFormControl = new UntypedFormControl();
  /** list of car make filtered by search keyword */
  public filteredCarMakeList: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredCarMakeListAr: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  /** control for the selected carModel */
  public carModelCtrl: UntypedFormControl = new UntypedFormControl();
  public carModelCtrlAr: UntypedFormControl = new UntypedFormControl();
  /** control for the MatSelect filter keyword */
  public carModelFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public carModelFilterCtrlAr: UntypedFormControl = new UntypedFormControl();
  /** list of car make filtered by search keyword */
  public filteredCarModelList: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredCarModelListAr: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  loaderDialogRef: MatDialogRef<LoaderDialogComponent>;

  constructor(private tplService: TPLService, private translateService: TranslateService, private snackBox: MatSnackBar, private catalogService: CatalogService, private dialog: MatDialog, private accountService: AccountService) {
    this.customer = new OgCustomer();

    this.selectedLicense = this.purposeOfLicenses[0];
    this.selectedLicenseAr = this.purposeOfLicensesAr[0];

    // Get TPL service Fees
    this.tplService.getFees(this.token).subscribe(fee => {
      this.fee = fee;
    });

    // Get Car Makes
    this.catalogService.getCarMakes().subscribe( carMakesResponse => {
      this.carMakeList = carMakesResponse;
      this.createCarMakeFilter();
    }, error => {
      this.snackBox.open( "Error occur while fetching Car Makes", 'Cancel', {
        duration: 2000,
        horizontalPosition: 'right'
      });
    });

    this.catalogService.getCarMakes('ar').subscribe( carMakesArResponse => {
      if ( carMakesArResponse ) {
        this.carMakeListAr = carMakesArResponse.filter( carMake => { if ( carMake ) return true } );
        this.createCarMakeFilterAr();
      }
    }, error => {});
  }

  ngOnInit() {
    this.tplService.IsOgProgess = true;
  }

  ngOnDestroy() {
    this.tplService.IsOgProgess = false;
    this._onDestroy.next();
    this._onDestroy.complete();

    if ( this.loaderDialogRef ) {
      this.loaderDialogRef.close();
    }
  }

  //#region Car Make filter
  
  createCarMakeFilter(): void {
    // load the initial car make list
    this.filteredCarMakeList.next(this.carMakeList.slice());

    // listen for search field value changes
    this.carMakeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCarMake();
      });

    this.carMakeCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarMake = this.carMakeCtrl.value;
      }, error => {}
    )
  }

  createCarMakeFilterAr(): void {
    // load the initial car make list
    this.filteredCarMakeListAr.next(this.carMakeListAr.slice());

    // listen for search field value changes
    this.carMakeFilterCtrlAr.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCarMakeAr();
      });

    this.carMakeCtrlAr.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarMakeAr = this.carMakeCtrlAr.value;
      }, error => {}
    )
  }

  protected filterCarMake() {
    if (!this.carMakeList) {
      return;
    }
    // get the search keyword
    let search = this.carMakeFilterCtrl.value;
    if (!search) {
      this.filteredCarMakeList.next(this.carMakeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CarMake
    this.filteredCarMakeList.next(
      this.carMakeList.filter( carMake => carMake.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterCarMakeAr() {
    if (!this.carMakeListAr) {
      return;
    }
    // get the search keyword
    let search = this.carMakeFilterCtrlAr.value;
    if (!search) {
      this.filteredCarMakeListAr.next(this.carMakeListAr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CarMake
    this.filteredCarMakeListAr.next(
      this.carMakeListAr.filter( carMake => carMake.toLowerCase().indexOf(search) > -1)
    );
  }

  //#endregion

  //#region  Car model filter

  createCarModelFilter(): void {
    // load the initial car model list
    this.filteredCarModelList.next(this.carModelList.slice());

    // listen for search field value changes
    this.carModelFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCarModel();
      });

    this.carModelCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarModel = this.carModelCtrl.value;
      }, error => {}
    )
  }

  createCarModelFilterAr(): void {
    // load the initial car model list
    this.filteredCarModelListAr.next(this.carModelListAr.slice());

    // listen for search field value changes
    this.carModelFilterCtrlAr.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCarModelAr();
      });

    this.carModelCtrlAr.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarModelAr = this.carModelCtrlAr.value;
      }, error => {}
    )
  }

  protected filterCarModel() {
    if (!this.carModelList) {
      return;
    }
    // get the search keyword
    let search = this.carModelFilterCtrl.value;
    if (!search) {
      this.filteredCarModelList.next(this.carModelList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CarModel
    this.filteredCarModelList.next(
      this.carModelList.filter( carModel => carModel.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterCarModelAr() {
    if (!this.carModelListAr) {
      return;
    }
    // get the search keyword
    let search = this.carModelFilterCtrlAr.value;
    if (!search) {
      this.filteredCarModelListAr.next(this.carModelListAr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CarModel
    this.filteredCarModelListAr.next(
      this.carModelListAr.filter( carModel => carModel.toLowerCase().indexOf(search) > -1)
    );
  }

  //#endregion car model filter

  getLanguage(): string {
    return this.translateService.lang;
  }

  getDeviceLink(): string {
    if ( !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) ) {
      return "https://bit.ly/3d4E2Wm";
    } else {
      return "https://bit.ly/2YkSjuf";
    }
  }
  
  // Get Car Models
  getCarModels() {
    let carModelSnackBarRef;
    if ( this.customer.carMake && this.translateService.lang == 'en' ) {
      this.catalogService.getCarModels( this.customer.carMake ). subscribe( carModelsResponse => {
        this.carModelList = carModelsResponse;
        this.createCarModelFilter();
      }, error => {
        carModelSnackBarRef = this.snackBox.open( "Error occur while fetching Car Models", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if ( this.customer.carMakeAr && this.translateService.lang == 'ar' ) {
      this.catalogService.getCarModels( this.customer.carMakeAr, 'ar'). subscribe( carModelsArResponse => {
        this.carModelListAr = carModelsArResponse;
        this.createCarModelFilterAr();
      }, error => {
        carModelSnackBarRef = this.snackBox.open( "Error occur while fetching Car Models", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if ( carModelSnackBarRef ) {
      carModelSnackBarRef.onAction().pipe( take(1) ).subscribe(
        action => {
          carModelSnackBarRef.dismiss();
          this.getCarModels();
        }, error => {});
    }
  }

  // Get Car trim
  getCarTrim() {
    let carTrimSnackBarRef;
    if ( this.customer.carMake && this.customer.carModel && this.translateService.lang == 'en' ) {
      this.catalogService.getCarTrim( this.customer.carMake, this.customer.carModel ). subscribe( carTrimResponse => {
        this.carTrimList = carTrimResponse;
      }, error => {
        carTrimSnackBarRef = this.snackBox.open( "Error occur while fetching Car Trim", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if ( this.customer.carMakeAr && this.customer.carModelAr && this.translateService.lang == 'ar' ) {
      this.catalogService.getCarTrim( this.customer.carMakeAr, this.customer.carModelAr, 'ar' ). subscribe( carTrimArResponse => {
        this.carTrimListAr = carTrimArResponse;
      }, error => {
        carTrimSnackBarRef = this.snackBox.open( "Error occur while fetching Car Trim", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if ( carTrimSnackBarRef ) {
      carTrimSnackBarRef.onAction().pipe( take(1) ).subscribe(
        action => {
          carTrimSnackBarRef.dismiss();
          this.getCarTrim();
        }, error => {});
    }
  }

  // Get PassengerCount
  getPassengerCount() {
    let passengerSnackBarRef;
    if ( this.customer.carMake && this.customer.carModel && this.customer.trim && this.translateService.lang == 'en' ) {
      this.catalogService.getPassengerCount( this.customer.carMake, this.customer.carModel, this.customer.trim, this.token ). subscribe( passengerCountResponse => {
        this.passengers = passengerCountResponse.passengers;
        this.getQuote();
      }, error => {
        passengerSnackBarRef = this.snackBox.open( "Error occur while fetching passenger count", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if ( this.customer.carMakeAr && this.customer.carModelAr && this.customer.trimAr && this.translateService.lang == 'ar' ) {
      this.catalogService.getPassengerCount( this.customer.carMakeAr, this.customer.carModelAr, this.customer.trimAr, this.token ). subscribe( passengerCountResponse => {
        this.passengers = passengerCountResponse.passengers;
        this.getQuote();
      }, error => {
        passengerSnackBarRef = this.snackBox.open( "Error occur while fetching passenger count", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if ( passengerSnackBarRef ) {
      passengerSnackBarRef.onAction().pipe( take(1) ).subscribe(
        action => {
          passengerSnackBarRef.dismiss();
          this.getPassengerCount();
        }, error => {});
    }
  }

  // Get Quote
  getQuote() {
    if ( this.customer.modelYear != null 
      && this.selectedLicense.id != null 
      && this.passengers != null 
      && this.load != null ) {
      this.hasQuote = false;
      this.tplService.getTplFees(this.customer.modelYear,  ( this.translateService.lang == 'en' ? this.selectedLicense.id : this.selectedLicenseAr.id ), this.passengers, this.load, this.token).subscribe(fees => {
        this.fees = fees;
        this.selectedFee = fees[0];
        this.hasQuote = true;
      }, error => {
        this.getQuoteRef =  this.snackBox.open( "Unable to retrieve payment information.", "Try again", { duration: 2000, horizontalPosition: 'right' } );

        this.getQuoteRef.onAction().pipe( take(1) ).subscribe( action => {
          this.getQuoteRef.dismiss();
          this.getQuote();
        }, error => {});
      });
    }
  }

  makePayment(form) {
    const policyDetails = {
      firstName: this.customer.firstName,
      lastName: this.customer.lastName,
      carMake: this.translateService.lang == 'en' ? this.customer.carMake : this.customer.carMakeAr,
      carModel: this.translateService.lang == 'en' ? this.customer.carModel : this.customer.carModelAr,
      modelYear: this.customer.modelYear,
      purposeOfLicense: this.translateService.lang == 'en' ? this.selectedLicense.name : this.selectedLicenseAr.name,
      noOfPassenger: this.passengers,
      policyDuration: this.selectedFee ? this.selectedFee.period : 0,
      carTrim: this.translateService.lang == 'en' ? this.customer.trim : this.customer.trimAr,
      email: "",
      tplId: this.selectedFee ? this.selectedFee.id : 0,
      basePremium: this.selectedFee.price,
      supervisionFee: this.getSupervisionFeeForPeriod( this.selectedFee.period ),
      issueFee: this.fee.issueFee,
      deliveryFee: this.fee.deliveryFee,
      convenienceFee: this.fee.convenienceFee,
      amountPaid: this.selectedFee.price + this.getSupervisionFeeForPeriod( this.selectedFee.period ) + this.fee.issueFee + this.fee.deliveryFee + this.fee.convenienceFee,
      countryCode: undefined,
      phoneNumber: undefined,
      tplPurchaseId: undefined,
      civilCardId: undefined,
      rcBookId: undefined,
      userId: this.userId
    };
    const dialogRef = this.dialog.open( PhoneNumberVerificationComponent, {
      data: policyDetails,
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(
      ( result: any ) => {
        if ( result && result.success ) {
          policyDetails.countryCode = result.countryCode;
          policyDetails.phoneNumber = result.phoneNumber;
          policyDetails.tplPurchaseId = result.tplPurchaseId;
          policyDetails.civilCardId = result.civilCardId;
          policyDetails.rcBookId = result.rcBookId;
          this.token = result.token;
          this.proceedForPaymentGateway( policyDetails );
        }

        if ( result.userId ) {
          this.userId = result.userId;
        }
      }, error => {
        this.snackBox.open( error, 'CANCEL', {
          duration: 2000,
          horizontalPosition: 'right'
        })
      }
    );
  }

  proceedForPaymentGateway(policyDetails: any): void {
    // responseurl
    let responseUrl = window.location.origin + '/redirect/' + this.token + 
    "/" + policyDetails.tplPurchaseId + 
    "/" + policyDetails.civilCardId + 
    "/" + policyDetails.rcBookId + 
    "/" + policyDetails.amountPaid + 
    "/" + this.translateService.lang;
    
    let description = encodeURIComponent('tplPurchase');
    let isysid = this.getTransactionId();
    let timestamp = ( new Date() ).getTime();
    let rnd = '';

    // Create hash for url
    let dataToComputeHash = environment.ogPaymetGateway.paymentChannel + "paymentchannel" +
    isysid + "isysid" +
    policyDetails.amountPaid + "amount" +
    timestamp + "timestamp" +
    description + "description" +
    rnd + "rnd" +
    environment.ogPaymetGateway.originalEn + "original" +
    policyDetails.phoneNumber + "msisdn" + 
    environment.ogPaymetGateway.currency + "currency" +
    environment.ogPaymetGateway.tunnel + "tunnel";

    this.loaderDialogRef = this.dialog.open(LoaderDialogComponent, {
      disableClose: true,
      id: 'loader-dialog',
      backdropClass: 'loader-backdrop'
    });

    this.accountService.getHASMAC( environment.ogPaymetGateway.originalDe, dataToComputeHash ).subscribe( response => {
      let paymentUrl = environment.ogPaymetGateway.url +
      "?paymentchannel=" + environment.ogPaymetGateway.paymentChannel +
      "&isysid=" + isysid +
      "&amount=" + policyDetails.amountPaid +
      "&tunnel=" + environment.ogPaymetGateway.tunnel +
      "&description=" + description +
      "&currency=" + environment.ogPaymetGateway.currency +
      "&language=EN&country=KW" +
      "&responseurl=" + responseUrl +
      "&merchant_name=" + environment.ogPaymetGateway.merchantName +
      "&akey=" + environment.ogPaymetGateway.authenticationKey +
      "&timestamp=" + timestamp +
      "&original=" + encodeURIComponent(environment.ogPaymetGateway.originalEn) +
      "&hash=" + response.hash +
      "&msisdn=" + policyDetails.phoneNumber +
      "&rnd=" + rnd;

      window.location.href = paymentUrl;
    }, error => {
      this.snackBox.open( error, 'CANCEL', {
        duration: 2000,
        horizontalPosition: 'right'
      });
      this.loaderDialogRef.close();
    });
  }

  // Supervision fee for selected period
  getSupervisionFeeForPeriod( period: number ): number {
    return ( period == 1 ? this.fee.supervisionFee : ( period == 2 ? ( this.fee.supervisionFee2 ? this.fee.supervisionFee2 : this.fee.supervisionFee ) : ( period == 3 ? ( this.fee.supervisionFee3 ? this.fee.supervisionFee3 : this.fee.supervisionFee ) : this.fee.supervisionFee ) ) );
  }

  getTransactionId(): string {
    let bRetVal: string = ("" + Math.random()).substring( 2, 14 );
    if ( bRetVal.charAt(0) == '0' ) {
      bRetVal = "" + Math.floor(1 + Math.random() * 8) + bRetVal.substring( 1, bRetVal.length );
    }
    return bRetVal;
  }

}
