import { Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit, DoCheck {

  title = 'saiyarti';
  showFooter = true;
  pageWithNoFooterList = [
    '/faqs-individuals',
    '/faqs-assist'
  ]

  constructor(private router: Router) { }

  ngOnInit() {

  }

  ngDoCheck(): void {
    this.showFooter = !( this.pageWithNoFooterList.findIndex( page => this.router.url.indexOf( page ) > -1 ) > -1 )
  }
}
