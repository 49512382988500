import { Component, OnInit, ViewChild } from '@angular/core';
import { GetInTouchService } from '../get-in-touch.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { TPLService } from '../tpl.service';

@Component({
  selector: 'app-saiyarti-footer',
  templateUrl: './saiyarti-footer.component.html',
  styleUrls: ['./saiyarti-footer.component.css']
})
export class SaiyartiFooterComponent implements OnInit {

  email: string;
  partnerPlayStore: any;
  partnerAppStore: any;
  @ViewChild('contactForm', { static: false }) contactForm;
  contactSubmitted = false;

  constructor(private getInTouchService: GetInTouchService, private snackBar: MatSnackBar, router: Router, private tplService: TPLService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (this.contactForm) {
          this.contactForm.reset();
        }
      }
    });
  }

  ngOnInit() {
  }
  submitContact(form) {
    const data = {
      email: form.value.email,
      message: 'I would like to subscribe to your newsletter',
      phoneNumber: '-',
      name: '-',
      source: 'Website',
      type: 'Subscribe'
    };

    // Regardless of success or error reset the form and show success
    this.contactSubmitted = true;
    form.reset();
    setTimeout(() => {
      this.contactSubmitted = false;
    }, 3000);
    this.getInTouchService.getInTouch(data).subscribe(res => { }, err => { });
  }

  scrollToTop() {
    this.contactForm.reset();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  isOgPayment() {
    return this.tplService.IsOgProgess;
  }
}
