export class Asma {
    name: string;
    nameAr: string;
    LastName: string;
    LastNameAr: string;
    detail: String;
    detailAr: string;
    detail2: String;
    detail2Ar: string;
}
