import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { Customer } from 'src/model/customer';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  public handleError(error: HttpErrorResponse | any) {
    let errMsg: any;
    console.log(error);

    if (error instanceof HttpErrorResponse) {
      const body = error.error || '';
      const err = body || JSON.stringify(body);
      errMsg = err.text;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    return throwError(errMsg);
  }


  public header(token?: string) {

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('apiKey', environment.apiKey);
    header = header.append('apiPassword', environment.apiPassword);
    if ( token ) {
      header = header.append('Authorization', 'Bearer ' + token);
    }
    return { headers: header };
  }

  getRenewCustomer(id: number, token: string): Observable<Customer> {
    return this.http.get<Customer>(environment.apiUrl + '/api/Account/GetRenewCustomer/' + id, this.header(token)).pipe(catchError(this.handleError));
  }

  getSASTokenForContainer(containerName: string, token: string): any {
    return this.http.post<any>(environment.apiUrl + '/api/Storage/GetReadOnlyTokenForContainer', { containerName }, this.header(token)).pipe(catchError(this.handleError));
  }

  getUploadOnlyTokenForContainer(containerName: string, token: string): any {
    return this.http.post<any>(environment.apiUrl + '/api/Storage/GetUploadOnlyTokenForContainer', { containerName }, this.header(token)).pipe(catchError(this.handleError));
  }

  updateDetails(data: any, token: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/api/Account/UpdateEmailTPLDetails', data, this.header(token)).pipe(catchError(this.handleError));
  }

  exchangeToken( token: string): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/api/Account/GetTokenForUser', this.header(token)).pipe(catchError(this.handleError));
  }

  sendOtp(data: any, lang?: string, token?: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/api/Account/SendOtp', data, this.header(token)).pipe(
      catchError(this.handleError)
    );
  }

  verifyOtp(data: any, lang?: string, token?: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/api/Account/VerifyOtp', data, this.header(token)).pipe(
      catchError(this.handleError)
    );
  }

  getHASMAC(originalDe: string, dataToComputeHash: string, token?: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/api/Account/GetHASMAC?original=' + originalDe + "&dataToComputeHash=" + dataToComputeHash, null, this.header(token)).pipe(
      catchError(this.handleError)
    );
  }
}
