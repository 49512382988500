import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';


import * as HesabeCrypt from 'hesabe-crypt';
import * as aesjs from 'aes-js';
import { TranslateService } from '../translate.service';
import { TPLService } from '../tpl.service';
declare let $: any;

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.css']
})
export class PaymentFailureComponent implements OnInit, OnDestroy {

  payment: HesabeCrypt;
  Link = '/policy-detail';
  LinkOg = '/policy-detail-og';
  token: string;
  id: string;
  isOgPaymentFail: boolean;

  constructor(activatedRoute: ActivatedRoute, private translateService: TranslateService,
    private router: Router, private tplService: TPLService) {

    const secret = environment.paymetGateway.secret;
    const ivKey = environment.paymetGateway.ivKey;

    const key = aesjs.utils.utf8.toBytes(secret);
    const iv = aesjs.utils.utf8.toBytes(ivKey);
    this.payment = new HesabeCrypt(key, iv);

    activatedRoute.queryParams.subscribe(
      params => {
        if (params.data) {
          const decrypted = this.payment.decryptAes(params.data);
          console.log(decrypted);
          const paymentResponse = JSON.parse(decrypted);
          this.setLang(paymentResponse.response.variable4);
          this.id = paymentResponse.response.variable5;
        }

        if ( params.isOg ) {
          this.isOgPaymentFail = true;
          if ( params.v4 ) {
            this.setLang(params.v4);
          }
        } else {
          this.isOgPaymentFail = false;
        }
        this.tplService.IsOgProgess = this.isOgPaymentFail;

        if (params.token) {
          this.token = params.token;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.tplService.IsOgProgess = false;
  }

  ngOnInit() {
  }


  setLang(lang: string) {
    this.translateService.use(lang);
    $(document).ready(() => {
      $('body').removeClass('en');
      $('body').removeClass('ar');
      $('body').addClass(lang);
    });
  }

  retry() {
    if ( this.isOgPaymentFail ) {
      this.router.navigate([this.LinkOg], { queryParams: { token: this.token } });
    } else {
      this.router.navigate([this.Link], { queryParams: { token: this.token, id: this.id } });
    }
  }
}
