import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DuplicateNumberErrorDialogComponent } from './dialog/duplicate-number-error-dialog/duplicate-number-error-dialog.component';
import { VideoPlayDialogComponent } from './dialog/video-play-dialog/video-play-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogServiceService {

  constructor(private dialog: MatDialog) { }

  videoPlayDialog(videoUrl: String){
    return this.dialog.open(VideoPlayDialogComponent,{
      width: '80%',
      data: {url: videoUrl}
    })
  }

  duplicateNumberErrorDialog( aData: any ) {
    return this.dialog.open( DuplicateNumberErrorDialogComponent, {
      width: '930px',
      data: aData
    });
  }

}
