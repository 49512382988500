import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-brand-partner',
  templateUrl: './brand-partner.component.html',
  styleUrls: ['./brand-partner.component.css']
})
export class BrandPartnerComponent implements OnInit {

  name: string;
  phoneNumber: string;
  constructor(private titleService: Title) {
    this.titleService.setTitle('Saiyarti-Brand Partner');
  }

  ngOnInit() {
  }
}
