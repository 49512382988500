export class Customer {
    ownerName: string;
    phoneNumber1: string;
    phoneNumber2: string;
    phoneNumber3: string;
    phoneNumber4: string;
    plateNumber: string;
    carMake: string;
    carModel: string;
    modelYear: number;
    nationality: string;
    chassisNumber: string;
    color: string;
    expiryDate: Date;
    gender: string;
    dateOfBirth: Date;
    email: string;
}

export class OgCustomer {
    phoneNumber: string;
    carMake: string;
    carModel: string;
    modelYear: number;
    email: string;
    trim?: string;
    carMakeAr?: string;
    carModelAr?: string;
    trimAr?: string;
    firstName?: string;
    lastName?: string;
}