import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-payment-response-og',
  templateUrl: './payment-response-og.component.html',
  styleUrls: ['./payment-response-og.component.css']
})
export class PaymentResponseOgComponent implements OnInit {

  paymentFail: string = '/payment-failure';
  paymentSuccess: string = '/payment-success';

  amountPaid: number;
  civilCardId: string;
  lang: string;
  rcBookId: string;
  token: string;
  tplPurchaseId: string;
  paymentToken: string;
  paymentId: string;
  result: string;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router) {
    
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.paymentToken = params.refid;
        this.paymentId = params.pid;
        this.result = params.result;

        this.redirect();
      }, error => {});

    this.activatedRoute.paramMap.subscribe( params => {
      this.token = params.get("token");
      this.amountPaid = +params.get("amountPaid");
      this.civilCardId = params.get("civilCardId");
      this.rcBookId = params.get("rcBookId");
      this.tplPurchaseId = params.get("tplPurchaseId");
      this.lang = params.get("lang");
      
      this.redirect();
    }, error => {

    });
  }

  redirect(): void {
    if ( this.token && this.result ) {
      if ( this.result == "CAPTURED" ) {
        // if success
        // v1 = tplPurchaseId,
        // v2 = civilCardId,
        // v3 = rcBookId,
        // v4 = lang,
        // v5 = amountPaid
        this.router.navigate([this.paymentSuccess],
          { queryParams:
            { token: this.token,
              isOg: true,
              v1: this.tplPurchaseId,
              v2: this.civilCardId,
              v3: this.rcBookId,
              v4: this.lang,
              v5: this.amountPaid,
              paymentToken: this.paymentToken,
              paymentId: this.paymentId
            } 
          });
      } else {
        // if failed
        this.router.navigate([this.paymentFail], { queryParams: { token: this.token, isOg: true, v4: this.lang } });
      }
    }
  }

}
