import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { TPLService } from '../tpl.service';
import * as HesabeCrypt from 'hesabe-crypt';
import * as aesjs from 'aes-js';
// import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { AccountService } from '../account.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '../translate.service';
declare let $: any;

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit, OnDestroy {

  tplPurchaseId: number;
  rcBookId: number;
  civilCardId: number;
  amountPaid: number;
  token: string;
  payment: HesabeCrypt;
  hasPaymentSuccess: boolean;
  paymentToken: string;
  paymentId: string;
  status: string;
  deliveryAddress: string;
  deliveryInstructions: string;
  email: string;
  civilCardFrontFileName: string;
  civilCardBackFileName: string;
  rcBookkFileName: string;
  isOg: boolean;

  SASTokenForUploadCivil: string;
  SASTokenForUploadRCBook: string;
  // AzureConfig: UploadParams = {
  //   sas: '',
  //   storageAccount: environment.storageAccount,
  //   containerName: 'civil-card'
  // };

  // uploadConfig: UploadConfig;

  constructor(
    // private blobService: BlobService,
    private snackBar: MatSnackBar,
    private router: Router,
    activatedRoute: ActivatedRoute, private accountService: AccountService, private tplService: TPLService,
    private translateService: TranslateService) {
    const secret = environment.paymetGateway.secret;
    const ivKey = environment.paymetGateway.ivKey;

    const key = aesjs.utils.utf8.toBytes(secret);
    const iv = aesjs.utils.utf8.toBytes(ivKey);
    this.payment = new HesabeCrypt(key, iv);

    activatedRoute.queryParams.subscribe(
      params => {
        if (params.data) {
          const decrypted = this.payment.decryptAes(params.data);
          console.log(decrypted);
          const paymentResponse = JSON.parse(decrypted);
          this.hasPaymentSuccess = paymentResponse.code === 1;

          this.civilCardId = paymentResponse.response.variable2;
          this.rcBookId = paymentResponse.response.variable3;

          this.setLang(paymentResponse.response.variable4);

          this.tplPurchaseId = +paymentResponse.response.variable1;
          this.amountPaid = paymentResponse.response.amount;
          this.paymentToken = paymentResponse.response.paymentToken;
          this.paymentId = paymentResponse.response.paymentId;
          this.status = this.hasPaymentSuccess ? 'success' : 'failure';
        }

        if ( params.isOg ) {
          this.tplPurchaseId = +params.v1;
          this.civilCardId = params.v2;
          this.rcBookId = params.v3;
          if ( params.v4 ) {
            this.setLang(params.v4);
          }
          this.amountPaid = params.v5;
          this.paymentToken = params.paymentToken;
          this.paymentId = params.paymentId;
          this.status = 'success';
          this.isOg = params.isOg;
          this.tplService.IsOgProgess = this.isOg;
        }

        if (params.token && !params.isOg) {
          this.accountService.exchangeToken(params.token).subscribe(tokenRes => {
            this.token = tokenRes.token;
            if (this.token && this.tplPurchaseId) {
              const data = {
                tplPurchaseId: this.tplPurchaseId,
                amountPaid: this.amountPaid,
                status: this.status,
                paymentToken: this.paymentToken,
                paymentId: this.paymentId
              };
              this.tplService.completeTransaction(
                data, this.token).subscribe(res => {

                }, err => {
                  this.snackBar.open(err, 'CANCEL', {
                    duration: 2000,
                  });
                });
            }
            accountService.getUploadOnlyTokenForContainer('civil-card', this.token).subscribe(token => {
              this.SASTokenForUploadCivil = token.sasToken;
            });

            accountService.getUploadOnlyTokenForContainer('rc-book', this.token).subscribe(token => {
              this.SASTokenForUploadRCBook = token.sasToken;
            });
          });
        } else if ( params.isOg ) {
          this.token = params.token;
          if (this.token && this.tplPurchaseId) {
            const data = {
              tplPurchaseId: this.tplPurchaseId,
              amountPaid: this.amountPaid,
              status: this.status,
              paymentToken: this.paymentToken,
              paymentId: this.paymentId
            };
            this.tplService.completeTransaction(
              data, this.token).subscribe(res => {

              }, err => {
                this.snackBar.open(err, 'CANCEL', {
                  duration: 2000,
                });
              });
          }
          accountService.getUploadOnlyTokenForContainer('civil-card', this.token).subscribe(token => {
            this.SASTokenForUploadCivil = token.sasToken;
          });

          accountService.getUploadOnlyTokenForContainer('rc-book', this.token).subscribe(token => {
            this.SASTokenForUploadRCBook = token.sasToken;
          });
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.tplService.IsOgProgess = false;
  }

  ngOnInit() { }

  setLang(lang: string) {
    this.translateService.use(lang);
    $(document).ready(() => {
      $('body').removeClass('en');
      $('body').removeClass('ar');
      $('body').addClass(lang);
    });
  }

  updateDetails(form) {

    const data = {
      tplPurchaseId: this.tplPurchaseId,
      deliveryAddress: this.deliveryAddress,
      deliveryInstructions: this.deliveryInstructions,
      email: this.email
    };
    this.accountService.updateDetails(
      data, this.token).subscribe(res => {
        this.scrollToTop();
        this.router.navigate(['/details-updated'], { queryParams: { token: this.token, isOg: this.isOg} });
      }, err => {
        this.snackBar.open(err, 'CANCEL',{
          duration: 2000,
        });
      });
  }

  scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  uploadDoc(event) {
    // const file = event.target.files[0];
    // if (file !== undefined) {
    //   let fileName = '';
    //   if (event.srcElement.id === 'civilCardFront' || event.srcElement.id === 'civilCardBack') {
    //     this.AzureConfig.sas = this.SASTokenForUploadCivil;
    //     this.AzureConfig.containerName = 'civil-card';
    //     if (event.srcElement.id === 'civilCardFront') {
    //       this.civilCardFrontFileName = file.name;
    //       fileName = this.civilCardId + '_front.jpg';
    //     } else {
    //       this.civilCardBackFileName = file.name;
    //       fileName = this.civilCardId + '_back.jpg';
    //     }
    //   } else if (event.srcElement.id === 'rcBook') {
    //     this.AzureConfig.sas = this.SASTokenForUploadRCBook;
    //     this.AzureConfig.containerName = 'rc-book';
    //     fileName = this.rcBookId + '.jpg';
    //     this.rcBookkFileName = file.name;
    //   }
    //   this.uploadFile(file, fileName, (x) => { });
    // }
  }

  uploadFile(yourfile: any, fileName: string, cb: (error: Error) => void) {

    // if (yourfile !== null) {
    //   const baseUrl = this.blobService.generateBlobUrl(this.AzureConfig, fileName);
    //   this.uploadConfig = {
    //     baseUrl,
    //     sasToken: this.AzureConfig.sas,
    //     // blockSize: 1024 * 64, // OPTIONAL, default value is 1024 * 32
    //     file: yourfile,
    //     complete: () => {
    //       cb(null);
    //       this.snackBar.open('File successfully uploaded', 'CANCEL', { duration: 1000 });
    //     },
    //     error: (err) => {
    //       this.snackBar.open('There was an error uploading your document: ' + fileName, 'CANCEL', { duration: 1000 });
    //     },
    //     progress: (percent) => {
    //       // this.percent = percent;
    //     }
    //   };
    //   // Upload to server
    //   this.blobService.upload(this.uploadConfig);
    // }
  }
}
