import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../translate.service';
declare var $: any;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {

  constructor(private translate: TranslateService) {
    // console.log(translate.data);
  }

  scroll(el) {
    document.getElementById(el).scrollIntoView();
}

  setLang(lang: string) {
    this.translate.use(lang);
    $(document).ready( () => {
      $('body').removeClass('en');
      $('body').removeClass('ar');
      $('body').addClass(lang);
    });
  }

  
  ngOnInit() {
    $(document).ready(() => {
      $('.collapse').on('show.bs.collapse', () => {
        $('.navbar-bg').css('background-color', '#f8f9fa');
        $('.navbar-bg').addClass('navbar-fixed');
        $('.conteint-box').css('padding-top', '54px');
        // $('.logo-car.mob,.mobileApp').hide();

      });
      $('.collapse').on('hidden.bs.collapse', () =>  {
        $('.navbar-bg').css('background-color', '');
        $('.navbar-bg').removeClass('navbar-fixed');
        $('.conteint-box').css('padding-top', '0');
        // $('.logo-car.mob').show();
      });
    });
  }

}
