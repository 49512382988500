import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { OwlModule } from 'ngx-owl-carousel';
import { NgwWowModule } from 'ngx-wow';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';

import { TranslateService } from './translate.service';
import { TranslatePipe } from './translate.pipe';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { GetInTouchService } from '../app/get-in-touch.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VerifiedEmailComponent } from './success/verified-email/verified-email.component';
import { ChangepasswordSuccessComponent } from './success/changepassword-success/changepassword-success.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { VideoPlayDialogComponent } from './dialog/video-play-dialog/video-play-dialog.component';
import { DialogServiceService } from './dialog-service.service';
import { DownloadComponent } from './download/download.component';
import { SaiyartiFooterComponent } from './saiyarti-footer/saiyarti-footer.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SolutionComponent } from './solution/solution.component';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { BrandPartnerComponent } from './brand-partner/brand-partner.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { FaqsPageComponent } from './faqs-page/faqs-page.component';
import { BlogPageComponent } from './blog-page/blog-page.component';
import { FeaturedOnComponent } from './featured-on/featured-on.component';
import { HeaderComponent } from './header/header.component';
import { NumericDirective } from './directive/numeric-directive';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { MakePaymentComponent } from './make-payment/make-payment.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailureComponent } from './payment-failure/payment-failure.component';
import { PolicyDetailComponent } from './policy-detail/policy-detail.component';
import { TPLService } from './tpl.service';
import { AccountService } from './account.service';
// import { BlobService } from 'angular-azure-blob-service';
import { SaiyartiBusinessComponent } from './saiyarti-business/saiyarti-business.component';
import { BusinessPlatformComponent } from './business-platform/business-platform.component';
import { DetailsUpdatedComponent } from './details-updated/details-updated.component';
import { SaiyartiAssistComponent } from './assist/assist.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { AssistTermsAndConditionComponent } from './assist-terms-and-condition/assist-terms-and-condition.component';
import { FaqsIndividualsComponent } from './faqs-individuals/faqs-individuals.component';
import { FaqsAssistComponent } from './faqs-assist/faqs-assist.component';
import { PolicyDetailOgComponent } from './policy-detail-og/policy-detail-og.component';
import { PhoneNumberVerificationComponent } from './dialog/phone-number-verification/phone-number-verification.component';
import { PaymentResponseOgComponent } from './payment-response-og/payment-response-og.component';
import { DuplicateNumberErrorDialogComponent } from './dialog/duplicate-number-error-dialog/duplicate-number-error-dialog.component';
import { LoaderDialogComponent } from './dialog/loader-dialog/loader-dialog.component';
import { ComprehensiveComponent } from './comprehensive/comprehensive/comprehensive.component';



const appRoutes: Routes = [

  { path: 'verified-email', component: VerifiedEmailComponent },
  { path: 'changepassword-success', component: ChangepasswordSuccessComponent },

  {
    path: '', component: MainLayoutComponent,
    children: [
      // { path: '', component: HomeComponent },
      // { path: 'aboutus', component: AboutUsComponent },
      // { path: 'termsandcondition', component: TermsAndConditionComponent },
      // { path: 'faqs', component: FaqsComponent },
      // { path: 'partners/:partner', component: DownloadComponent },
      // { path: 'home-page', component: HomePageComponent },
      // { path: 'Solution', component: SolutionComponent },
      // { path: 'about-us-page', component: AboutUsPageComponent },
      // { path: 'brand-partner', component: BrandPartnerComponent },
      // { path: 'faqs-page', component: FaqsPageComponent },
      // { path: 'saiyarti-footer', component: SaiyartiFooterComponent }

      { path: '', component: HomePageComponent },
      { path: 'aboutus', component: AboutUsPageComponent },
      { path: 'termsandcondition', component: TermsAndConditionComponent },
      { path: 'termsandcondition/:id', component: TermsAndConditionComponent },
      { path: 'faqs', component: FaqsPageComponent },
      { path: 'faqs-individuals', component: FaqsIndividualsComponent },
      { path: 'faqs-assist', component: FaqsAssistComponent },
      { path: 'partners/:partner', component: DownloadComponent },
      { path: 'solution', component: SolutionComponent },
      { path: 'brand-partner', component: BrandPartnerComponent },
      { path: 'blog/:id', component: BlogPageComponent },
      { path: 'blog', component: BlogPageComponent },
      { path: 'featured', component: FeaturedOnComponent },
      { path: 'contact-us', component: ContactUsPageComponent },
      { path: 'make-payment', component: MakePaymentComponent },
      { path: 'payment-success', component: PaymentSuccessComponent },
      { path: 'redirect/:token/:tplPurchaseId/:civilCardId/:rcBookId/:amountPaid/:lang', component: PaymentResponseOgComponent },
      { path: 'payment-failure', component: PaymentFailureComponent },
      { path: 'policy-detail', component: PolicyDetailComponent },
      { path: 'policy-detail-og', component: PolicyDetailOgComponent },
      { path: 'fleet', component: SaiyartiBusinessComponent },
      { path: 'business-platform', component: BusinessPlatformComponent },
      { path: 'details-updated', component: DetailsUpdatedComponent },
      { path: 'assist', component: SaiyartiAssistComponent },
      { path: 'comprehensive', component: ComprehensiveComponent },
    ]
  }
]

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use(window.localStorage.language);
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        FooterComponent,
        NavMenuComponent,
        TranslatePipe,
        ContactUsComponent,
        AboutUsComponent,
        TermsAndConditionComponent,
        VerifiedEmailComponent,
        ChangepasswordSuccessComponent,
        MainLayoutComponent,
        VideoPlayDialogComponent,
        DownloadComponent,
        SaiyartiFooterComponent,
        HomePageComponent,
        SolutionComponent,
        AboutUsPageComponent,
        BrandPartnerComponent,
        FaqsPageComponent,
        BlogPageComponent,
        FeaturedOnComponent,
        HeaderComponent,
        NumericDirective,
        ContactUsPageComponent,
        MakePaymentComponent,
        PaymentSuccessComponent,
        PaymentFailureComponent,
        PolicyDetailComponent,
        SaiyartiBusinessComponent,
        BusinessPlatformComponent,
        DetailsUpdatedComponent,
        SaiyartiAssistComponent,
        DownloadAppComponent,
        AssistTermsAndConditionComponent,
        FaqsIndividualsComponent,
        FaqsAssistComponent,
        PolicyDetailOgComponent,
        PhoneNumberVerificationComponent,
        PaymentResponseOgComponent,
        DuplicateNumberErrorDialogComponent,
        LoaderDialogComponent,
        ComprehensiveComponent
    ],
    imports: [
        NgbModule,
        BrowserModule,
        AppRoutingModule,
        RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' }),
        RouterModule.forChild(appRoutes),
        HttpClientModule,
        NgwWowModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatDialogModule,
        NgbCarouselModule,
        BrowserAnimationsModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        OwlModule,
        MatCardModule,
        MatOptionModule,
        MatSelectModule,
        NgxMatSelectSearchModule
    ],
    providers: [TranslateService, {
            provide: APP_INITIALIZER,
            useFactory: setupTranslateFactory,
            deps: [TranslateService],
            multi: true,
        },
        GetInTouchService,
        DialogServiceService,
        TPLService,
        AccountService
    ],
    exports: [RouterModule],
    bootstrap: [AppComponent]
})
export class AppModule { }
